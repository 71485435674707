import { i18n as linguiI18n } from "@lingui/core";

import { messages as messagesFr } from "./fr/messages";
import { messages as messagesEn } from "./en/messages";

const defaultLocale = "fr";

linguiI18n.load({
  fr: messagesFr,
  en: messagesEn,
});
linguiI18n.activate(defaultLocale);
/**
 * Get the i18n instance used by Lingui.
 */
export const i18n = linguiI18n;
