import { createManualStoreContext } from "@util/createProvider";
import { AllDiscussionsDocument, FindDiscussionDocument } from "../../gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { ReactNode } from "react";
import { useMutation } from "@tanstack/react-query";
import { cloudinaryUpload } from "@api/cloudinary";

/**
 * Returns an object containing the necessary functions and data for querying discussions related to a tattoo.
 *
 * @return {Object} An object with the following properties:
 *   - `tattooDiscussionQuery`: A function for querying discussions related to a specific tattoo.
 *   - `tattooDiscussion`: The data returned from the query.
 *   - `tattooDiscussionLoading`: A boolean indicating if the query is currently loading.
 *   - `allDiscussionsQuery`: A function for querying all discussions.
 *   - `allDiscussions`: The data returned from the query.
 *   - `allDiscussionsLoading`: A boolean indicating if the query is currently loading.
 */
function useDiscussionStore() {
  const [tattooDiscussionQuery, { data: tattooDiscussion, loading: tattooDiscussionLoading }] =
    useLazyQuery(FindDiscussionDocument);
  const [allDiscussionsQuery, { data: allDiscussions, loading: allDiscussionsLoading }] =
    useLazyQuery(AllDiscussionsDocument);

  const uploadImageToCDN = useMutation({
    mutationFn: (file: string) => cloudinaryUpload(file),
  });

  return {
    tattooDiscussionQuery,
    tattooDiscussion,
    tattooDiscussionLoading,
    allDiscussionsQuery,
    allDiscussions,
    allDiscussionsLoading,
    uploadImageToCDN,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useDiscussionStore);

type DiscussionProviderProps = {
  children: ReactNode;
};

/**
 * Renders a DiscussionProvider component that wraps its children with a StoreProvider component.
 *
 * @param {DiscussionProviderProps} props - The properties for the DiscussionProvider component.
 * @param {ReactNode} props.children - The children components to be wrapped by the StoreProvider.
 * @return {JSX.Element} The rendered DiscussionProvider component.
 */
export function DiscussionProvider({ children }: DiscussionProviderProps) {
  const discussionStore = useDiscussionStore();
  return <StoreProvider store={discussionStore}>{children}</StoreProvider>;
}
export const useDiscussion = useStore;
