import { createManualStoreContext } from "@util/createProvider";
import { useMemo, useState } from "react";
import { FindAllTattooSalonsInput } from "../../gql/graphql";

/**
 * A custom hook that manages the state of tattoo filter values.
 *
 * It provides functions to update the filter values and reset the filter.
 *
 * @return {Object} An object containing the current filter values and functions to update them.
 */
function useTattooFilterStore() {
  const [filterValue, setFilterValue] = useState<FindAllTattooSalonsInput>({
    name: "",
    isCertified: null,
    city: null,
    score: null,
  });

  const getNbActiveFilters = useMemo(() => {
    return Object.values({ ...filterValue, name: null }).filter((value) => value !== null).length;
  }, [filterValue]);

  /**
   * Updates the name filter value.
   *
   * @param {string | null} name - The new name filter value.
   * @return {void}
   */
  function setName(name: string | null) {
    setFilterValue({ ...filterValue, name });
  }

  /**
   * Updates the city filter value.
   *
   * @param {string | null} city - The new city filter value.
   * @return {void}
   */
  function setCity(city: string | null) {
    setFilterValue({ ...filterValue, city: city ? city.toLocaleLowerCase() : null });
  }

  /**
   * Updates the score filter value.
   *
   * @param {number | null} score - The new score filter value.
   * @return {void}
   */
  function setScore(score: number | null) {
    setFilterValue({ ...filterValue, score });
  }

  /**
   * Updates the certified filter value.
   *
   * @param {boolean | null} isCertified - The new certified filter value.
   * @return {void}
   */
  function setCertified(isCertified: boolean | null) {
    setFilterValue({ ...filterValue, isCertified });
  }

  /**
   * Resets the filter values to their default state, except for the name filter.
   *
   * @return {void}
   */
  function resetFilter() {
    setFilterValue({ name: filterValue.name, isCertified: null, city: null, score: null });
  }

  return {
    filterValue,
    setName,
    setCity,
    setScore,
    setCertified,
    resetFilter,
    getNbActiveFilters,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useTattooFilterStore);

export function TattooFilterProvider({ children }: { children: React.ReactNode }) {
  const tattooFilterStore = useTattooFilterStore();
  return <StoreProvider store={tattooFilterStore}>{children}</StoreProvider>;
}
export const useTattooFilter = useStore;
