import { AdvancedImage, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { FlexRowLayout } from "reonelabs-ui";
import styled from "styled-components";

export type ImageStyleProps = {
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  borderRadius?: string;
  height?: string;
};
type DisplayImageProps = {
  imageID: string;
  style: ImageStyleProps;
};
/**
 * A component that displays an image from Cloudinary.
 *
 * @param {string} imageID - The public ID of the image to display.
 * @param {ImageStyleProps} style - The CSS style to apply to the image.
 * @return {JSX.Element} The rendered component.
 */
export function DisplayImage({ imageID, style }: DisplayImageProps) {
  const cld = new Cloudinary({ cloud: { cloudName: "dqnacyz8p" } });

  return (
    <DisplayImageContainer>
      <AdvancedImage
        loading="lazy"
        cldImg={cld
          .image(imageID)
          .format("auto")
          .quality("auto")
          .resize(auto().gravity(autoGravity()))
          .addFlag("progressive:semi")}
        plugins={[lazyload()]}
        style={style}
      />
    </DisplayImageContainer>
  );
}

const DisplayImageContainer = styled(FlexRowLayout)`
  img {
    width: 100%;
    object-fit: cover;
  }
`;
