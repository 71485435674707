import { ReactNode } from "react";
import { createManualStoreContext } from "../util/createProvider";
import { useMutation } from "@tanstack/react-query";
import { post } from "../util/axios";
import { googleLogout } from "@react-oauth/google";
import { ResponseData } from "../types";
import { User } from "../../gql/graphql";

type LoginDTO = {
  token: string | undefined;
};

/**
 * Custom React hook for managing user authentication state.
 *
 * @return {Object} An object containing the connectUser function, isLogged boolean, and logoutUser function.
 */
function useAuthStore() {
  const isLogged = localStorage.getItem("user");
  const connectUser = useMutation<ResponseData<User>, Error, LoginDTO>({
    mutationFn: async (data: LoginDTO) => post("login", data),
  });

  /**
   * Logout the user by performing necessary clean-up tasks.
   */
  const logoutUser = () => {
    googleLogout();
    localStorage.removeItem("user");
    window.location.reload();
  };

  return {
    connectUser,
    isLogged,
    logoutUser,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useAuthStore);

type AuthProviderProps = {
  children: ReactNode;
};

/**
 * AuthProvider component for providing authentication context to its children.
 *
 * @param {AuthProviderProps} children - The child components that need access to the authentication context.
 * @return {ReactNode} The components wrapped with the authentication context.
 */
export function AuthProvider({ children }: AuthProviderProps) {
  const authStore = useAuthStore();

  return <StoreProvider store={authStore}>{children}</StoreProvider>;
}

export const useAuth = useStore;
