import { apiUrl } from "@config/api.config";
import axios from "axios";

/**
 * Uploads a file to Cloudinary using the provided file and upload preset.
 *
 * @param {string} file - The file to be uploaded.
 * @return {Promise<AxiosResponse>} A Promise that resolves to the Axios response from the upload request.
 */
export async function cloudinaryUpload(file: string) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "MESS_IMAGE");

  return await axios.post("https://api.cloudinary.com/v1_1/dqnacyz8p/upload", formData);
}

/**
 * Deletes a file from Cloudinary using the provided public ID.
 *
 * @param {string} publicId - The public ID of the image to delete.
 * @return {Promise<AxiosResponse>} A Promise that resolves to the Axios response from the delete request.
 */
export async function cloudinaryDestroy(publicId: string) {
  return await axios.delete(`${apiUrl}/api/destroy-image`, { data: { publicId } });
}
