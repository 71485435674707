import { toast, ToastContent, ToastOptions } from "react-toastify";

type Notification = (content?: ToastContent, options?: ToastOptions) => void;

interface UseNotifyResult {
  success: Notification;
  error: Notification;
  info: Notification;
}

const notifyApi: UseNotifyResult = {
  success: (content, options) => toast.success(content, options),
  error: (content, options) => toast.error(content, options),
  info: (content, options) => toast.info(content, options),
};

/**
 * Generates a hook for using notifications.
 *
 * @return {UseNotifyResult} the notification API
 */
export const useNotify = (): UseNotifyResult => {
  return notifyApi;
};
