import { useNavigate } from "@tanstack/react-router";
import { FlexColumnLayout } from "reonelabs-ui";
import { DarkModeValues, useDarMode } from "reonelabs-ui/src/lib/provider/darkMode.provider";
import { StyledPropsFormatted } from "reonelabs-ui/src/lib/types";
import styled from "styled-components";

type CardLayoutProps = {
  children: React.ReactNode;
  redirectUrl?: string;
};

/**
 * Renders a card layout component with optional redirect functionality.
 *
 * @param {CardLayoutProps} props - The props object containing the children and redirectUrl.
 * @param {React.ReactNode} props.children - The content to be rendered inside the card layout.
 * @param {string} props.redirectUrl - The URL to redirect to when the card layout is clicked.
 * @return {JSX.Element} The rendered card layout component.
 */
export function CardLayout({ children, redirectUrl }: CardLayoutProps) {
  const { darkMode } = useDarMode();
  const navigate = useNavigate();
  /**
   * Redirects to the specified URL if a redirect URL is provided.
   *
   * @return {void} This function does not return anything.
   */
  function redirect() {
    if (!redirectUrl) return;
    navigate({ to: redirectUrl });
  }

  return (
    <CardLayoutContainer $darkMode={darkMode} onClick={redirect} $isClickable={!!redirectUrl}>
      {children}
    </CardLayoutContainer>
  );
}

type CardLayoutContainerProps = {
  darkMode: DarkModeValues;
  isClickable: boolean;
};

const CardLayoutContainer = styled(FlexColumnLayout)<
  StyledPropsFormatted<CardLayoutContainerProps>
>`
  ${({ $isClickable }) => $isClickable && `cursor: pointer;`}
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings["32px"]};
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings["32px"]};
  border-radius: var(--Border-Radius-rounded-md, 6px);
  border: 1px solid var(--colors-indigo-300);
  background: var(--colors-indigo-100);
`;
