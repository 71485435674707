import { useTattooSalon } from "@provider/tattooSalon.provider";
import { useEffect } from "react";
import { ChevronLeft, Icon, PatchCheckFill } from "react-bootstrap-icons";
import { FlexRowLayout, Text } from "reonelabs-ui";
import styled from "styled-components";
import { Loader } from "../Loader";
import { useNavigate } from "@tanstack/react-router";
import { useUser } from "@provider/user.provider";

type TattooHeaderProps = {
  NameIcon?: Icon;
  RightIcon?: Icon;
  tattooEmail: string;
  clientEmail: string;
  backRedirectUrl: string;
  isTattooAccount?: boolean;
};

/**
 * Renders a header component for a tattoo shop.
 *
 * @param {Object} props - The component props.
 * @param {Icon} props.NameIcon - The icon to display next to the shop name.
 * @param {Icon} props.RightIcon - The icon to display on the right side of the header.
 * @param {string} props.tattooEmail - The email of the tattoo shop.
 * @param {string} props.backRedirectUrl - The URL to navigate back to.
 * @return {JSX.Element} The rendered header component.
 */
export function TattooHeader({
  NameIcon,
  RightIcon,
  tattooEmail,
  backRedirectUrl,
  isTattooAccount,
  clientEmail,
}: TattooHeaderProps) {
  const { tattooByEmailQuery, tattooByEmailLoading, tattooByEmail } = useTattooSalon();
  const { getUserByEmailQuery, getUserByEmailData, getUserByEmailLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTattooAccount) {
      getUserByEmailQuery({
        variables: { email: clientEmail },
      });
    }
    tattooByEmailQuery({
      variables: { email: tattooEmail },
    });
  }, [clientEmail, getUserByEmailQuery, isTattooAccount, tattooByEmailQuery, tattooEmail]);

  if (tattooByEmailLoading || getUserByEmailLoading) return <Loader />;

  /**
   * Handles the back navigation by navigating to the specified back redirect URL.
   *
   * @return {void} This function does not return anything.
   */
  function handleBack() {
    navigate({ to: backRedirectUrl });
  }

  return (
    <TattooHeaderContainer $justifyContent="space-between" $alignItems="center">
      <BackIcon size={24} onClick={handleBack} />
      <FlexRowLayout $gap={0.375}>
        <Text $size="DEFAULT" $isBold>
          {isTattooAccount
            ? getUserByEmailData?.getUserByEmail?.name
            : tattooByEmail?.tattooSalonByEmail?.name}
        </Text>
        {tattooByEmail?.tattooSalonByEmail?.email && <PatchCheckFill size={24} />}
        {NameIcon && <NameIcon size={24} />}
      </FlexRowLayout>
      {RightIcon && <RightIcon size={24} />}
    </TattooHeaderContainer>
  );
}

const TattooHeaderContainer = styled(FlexRowLayout)`
  box-sizing: border-box;
  padding: 20px;
  align-self: stretch;
  border-bottom: 1px solid var(--Layout-Nav-Text, #a3a3a3);
`;

const BackIcon = styled(ChevronLeft)`
  cursor: pointer;
`;
