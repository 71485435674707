import { useUser } from "@provider/user.provider";
import { GoogleAuth, Navbar } from "../../component";
import { Outlet } from "@tanstack/react-router";

/**
 * Renders the Index component.
 *
 * @return {JSX.Element} The rendered Index component
 */
export const Index = () => {
  const { userConnected } = useUser();

  if (!userConnected) {
    return <GoogleAuth />;
  }

  return (
    <>
      <Outlet />
      <Navbar />
    </>
  );
};
