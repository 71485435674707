import { ReactNode, createContext, useContext } from "react";

type UseCreateStore<TStoreShape, TStoreParams> = (params: TStoreParams) => TStoreShape;

type ManualStoreProviderProps<TStoreShape> = {
  children: ReactNode;
  store: TStoreShape;
};

type ManualStoreValues<TStoreShape> = {
  StoreProvider: React.FC<ManualStoreProviderProps<TStoreShape>>;
  useStore: () => TStoreShape;
};

/**
 * Creates a manual store context for the given store shape and parameters.
 *
 * @param {UseCreateStore<TStoreShape, TStoreParams>} _useCreateStore - the function for creating the store
 * @return {ManualStoreValues<TStoreShape>} an object containing the StoreProvider and useStore
 */
export function createManualStoreContext<TStoreShape, TStoreParams>(
  _useCreateStore: UseCreateStore<TStoreShape, TStoreParams>,
): ManualStoreValues<TStoreShape> {
  const StoreContext = createContext<TStoreShape>({} as TStoreShape);

  /**
   * A function that provides a store to its children components.
   *
   * @param {ManualStoreProviderProps<TStoreShape>} children - the child components
   * @param {TStoreShape} store - the store to be provided
   * @return {JSX.Element} the provider component with the provided store
   */
  const StoreProvider = ({ children, store }: ManualStoreProviderProps<TStoreShape>) => {
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
  };

  const useStore = () => useContext(StoreContext);

  return {
    StoreProvider,
    useStore,
  };
}
