import { useLazyQuery } from "@apollo/client";
import {
  TattooSalonByEmailDocument,
  TattooSalonDocument,
  TattooSalonsDocument,
} from "../../gql/graphql";
import { createManualStoreContext } from "../util/createProvider";
import { useTattooFilter } from "./tattooFilter.provider";

/**
 * Custom hook to manage state related to tattoo salons.
 *
 */
function useTattooSalonStore() {
  const { filterValue } = useTattooFilter();
  const [
    tattooSalonsQuery,
    { data: allTattooSalons, loading: tattooSalonsLoading, refetch: tattooSalonsRefetch },
  ] = useLazyQuery(TattooSalonsDocument, {
    variables: {
      findAllTattooSalonsInput: {
        name: "",
        isCertified: null,
        city: null,
        score: null,
      },
    },
  });
  const [tattooSalonQuery, { data: tattooSalon, loading: tattooSalonLoading }] =
    useLazyQuery(TattooSalonDocument);

  const [tattooByEmailQuery, { data: tattooByEmail, loading: tattooByEmailLoading }] = useLazyQuery(
    TattooSalonByEmailDocument,
  );

  function submitFilter() {
    tattooSalonsQuery({
      variables: {
        findAllTattooSalonsInput: filterValue,
      },
    });
  }

  return {
    allTattooSalons,
    tattooSalonsLoading,
    tattooSalonQuery,
    tattooSalon,
    tattooSalonLoading,
    tattooSalonsRefetch,
    tattooByEmailQuery,
    tattooByEmail,
    tattooByEmailLoading,
    submitFilter,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useTattooSalonStore);

type TattooSalonProviderProps = {
  children: React.ReactNode;
};

/**
 * Creates a TattooSalonProvider component that wraps its children with a StoreProvider component
 * and provides the tattooSalonStore as the store prop.
 *
 * @param {TattooSalonProviderProps} children - The children components to be wrapped by the TattooSalonProvider.
 * @return {JSX.Element} The TattooSalonProvider component.
 */
export function TattooSalonProvider({ children }: TattooSalonProviderProps) {
  const tattooSalonStore = useTattooSalonStore();
  return <StoreProvider store={tattooSalonStore}>{children}</StoreProvider>;
}

export const useTattooSalon = useStore;
