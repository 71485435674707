import { StrictMode } from "react";
import { AppProvider } from "./AppProvider";

/**
 * Renders the App component.
 *
 * @return {JSX.Element} The AppProvider component.
 */
export function App() {
  return (
    <StrictMode>
      <AppProvider></AppProvider>
    </StrictMode>
  );
}
