import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AllDiscussionsInput = {
  userEmail: Scalars["String"]["input"];
};

export type CreateOpinionInput = {
  clientEmail?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  picture?: InputMaybe<Scalars["String"]["input"]>;
  rating?: InputMaybe<RatingInput>;
  tattooEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateRdvInput = {
  clientEmail?: InputMaybe<Scalars["String"]["input"]>;
  color?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  picture1?: InputMaybe<Scalars["String"]["input"]>;
  picture2?: InputMaybe<Scalars["String"]["input"]>;
  picture3?: InputMaybe<Scalars["String"]["input"]>;
  placement?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["String"]["input"]>;
  tattooEmail?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateTattooSalonInput = {
  city: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  hourPrice?: InputMaybe<Scalars["Float"]["input"]>;
  instagram?: InputMaybe<Scalars["String"]["input"]>;
  isCertified: Scalars["Boolean"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  minPrice?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  picture: Array<Scalars["String"]["input"]>;
  score: Scalars["Float"]["input"];
  type: Scalars["String"]["input"];
};

export type CreateUserInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type Discussion = {
  __typename?: "Discussion";
  _id?: Maybe<Scalars["String"]["output"]>;
  clientId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Array<Maybe<Message>>>;
  tattooId?: Maybe<Scalars["String"]["output"]>;
};

export type FindAllTattooSalonsInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  isCertified?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FindDiscussionInput = {
  clientId: Scalars["String"]["input"];
  idDiscussions?: InputMaybe<Scalars["String"]["input"]>;
  tattooId: Scalars["String"]["input"];
};

export type Message = {
  __typename?: "Message";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  receiverId?: Maybe<Scalars["String"]["output"]>;
  senderId?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelRdv?: Maybe<Rdv>;
  createOpinion: Opinion;
  createRdv: Rdv;
  createTattooSalon: TattooSalon;
  createUser: User;
  updateTattooSalon: TattooSalon;
  updateUser: User;
};

export type MutationCancelRdvArgs = {
  id: Scalars["String"]["input"];
};

export type MutationCreateOpinionArgs = {
  createOpinionInput: CreateOpinionInput;
};

export type MutationCreateRdvArgs = {
  createRdvInput: CreateRdvInput;
};

export type MutationCreateTattooSalonArgs = {
  createTattooSalonInput: CreateTattooSalonInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationUpdateTattooSalonArgs = {
  _id: Scalars["String"]["input"];
  updateTattooSalonInput: UpdateTattooSalonInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};

export type Opinion = {
  __typename?: "Opinion";
  _id?: Maybe<Scalars["String"]["output"]>;
  clientEmail?: Maybe<Scalars["String"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Rating>;
  tattooEmail?: Maybe<Scalars["String"]["output"]>;
};

export type OpinionStats = {
  __typename?: "OpinionStats";
  opinionLength?: Maybe<Scalars["Int"]["output"]>;
  scoreTotal?: Maybe<Scalars["Float"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  allDiscussions?: Maybe<Array<Maybe<Discussion>>>;
  artistOpinion?: Maybe<Array<Maybe<Opinion>>>;
  artistRdv?: Maybe<Array<Maybe<Rdv>>>;
  artistStats?: Maybe<OpinionStats>;
  clientRdv?: Maybe<Array<Maybe<Rdv>>>;
  findDiscussion?: Maybe<Discussion>;
  findOpinion?: Maybe<Opinion>;
  getUserByEmail?: Maybe<User>;
  getUserTattooSalonsFavorite?: Maybe<Array<Maybe<TattooSalon>>>;
  opinion?: Maybe<Array<Maybe<Opinion>>>;
  tattooSalon?: Maybe<TattooSalon>;
  tattooSalonByEmail?: Maybe<TattooSalon>;
  tattooSalons?: Maybe<Array<Maybe<TattooSalon>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type QueryAllDiscussionsArgs = {
  allDiscussionsInput: AllDiscussionsInput;
};

export type QueryArtistOpinionArgs = {
  email: Scalars["String"]["input"];
};

export type QueryArtistRdvArgs = {
  email: Scalars["String"]["input"];
};

export type QueryArtistStatsArgs = {
  email: Scalars["String"]["input"];
};

export type QueryClientRdvArgs = {
  email: Scalars["String"]["input"];
};

export type QueryFindDiscussionArgs = {
  findDiscussionInput: FindDiscussionInput;
};

export type QueryFindOpinionArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QueryGetUserTattooSalonsFavoriteArgs = {
  email: Scalars["String"]["input"];
};

export type QueryTattooSalonArgs = {
  _id: Scalars["String"]["input"];
};

export type QueryTattooSalonByEmailArgs = {
  email: Scalars["String"]["input"];
};

export type QueryTattooSalonsArgs = {
  findAllTattooSalonsInput: FindAllTattooSalonsInput;
};

export type QueryUserArgs = {
  _id: Scalars["String"]["input"];
};

export type Rating = {
  __typename?: "Rating";
  hygiene?: Maybe<Scalars["Int"]["output"]>;
  price?: Maybe<Scalars["Int"]["output"]>;
  sum?: Maybe<Scalars["Float"]["output"]>;
  sympathy?: Maybe<Scalars["Int"]["output"]>;
};

export type RatingInput = {
  hygiene?: InputMaybe<Scalars["Int"]["input"]>;
  price?: InputMaybe<Scalars["Int"]["input"]>;
  sympathy?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Rdv = {
  __typename?: "Rdv";
  _id?: Maybe<Scalars["String"]["output"]>;
  canceled?: Maybe<Scalars["Boolean"]["output"]>;
  clientEmail?: Maybe<Scalars["String"]["output"]>;
  color?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["String"]["output"]>;
  dateRdv?: Maybe<Scalars["String"]["output"]>;
  depositPayed?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discussionId?: Maybe<Scalars["String"]["output"]>;
  picture1?: Maybe<Scalars["String"]["output"]>;
  picture2?: Maybe<Scalars["String"]["output"]>;
  picture3?: Maybe<Scalars["String"]["output"]>;
  placement?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<RdvStatus>;
  tattooEmail?: Maybe<Scalars["String"]["output"]>;
  tattooPayed?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum RdvStatus {
  Canceled = "canceled",
  DemandSend = "demand_send",
  DepositToPaid = "deposit_to_paid",
  Done = "done",
  TattooToPaid = "tattoo_to_paid",
}

export type TattooSalon = {
  __typename?: "TattooSalon";
  _id?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  hourPrice?: Maybe<Scalars["Float"]["output"]>;
  instagram?: Maybe<Scalars["String"]["output"]>;
  isCertified?: Maybe<Scalars["Boolean"]["output"]>;
  lat?: Maybe<Scalars["Float"]["output"]>;
  lng?: Maybe<Scalars["Float"]["output"]>;
  minPrice?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  score?: Maybe<Scalars["Float"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type UpdateTattooSalonInput = {
  _id?: InputMaybe<Scalars["String"]["input"]>;
  score?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateUserInput = {
  _id: Scalars["String"]["input"];
  idSubscription?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationByEmailIsActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationBySMSIsActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  subscriptionDate?: InputMaybe<Scalars["String"]["input"]>;
  tattooSalonsFavorite?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type User = {
  __typename?: "User";
  _id?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  idSubscription?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notificationByEmailIsActive?: Maybe<Scalars["Boolean"]["output"]>;
  notificationBySMSIsActive?: Maybe<Scalars["Boolean"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Scalars["String"]["output"]>;
  subscriptionDate?: Maybe<Scalars["String"]["output"]>;
  tattooSalonsFavorite?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type DiscussionFieldsFragment = {
  __typename?: "Discussion";
  _id?: string | null;
  createdAt?: string | null;
  tattooId?: string | null;
  clientId?: string | null;
  message?: Array<{
    __typename?: "Message";
    id?: string | null;
    senderId?: string | null;
    receiverId?: string | null;
    content?: string | null;
    createdAt?: string | null;
  } | null> | null;
};

export type FindDiscussionQueryVariables = Exact<{
  findDiscussionInput: FindDiscussionInput;
}>;

export type FindDiscussionQuery = {
  __typename?: "Query";
  findDiscussion?: {
    __typename?: "Discussion";
    _id?: string | null;
    createdAt?: string | null;
    tattooId?: string | null;
    clientId?: string | null;
    message?: Array<{
      __typename?: "Message";
      id?: string | null;
      senderId?: string | null;
      receiverId?: string | null;
      content?: string | null;
      createdAt?: string | null;
    } | null> | null;
  } | null;
};

export type AllDiscussionsQueryVariables = Exact<{
  allDiscussionsInput: AllDiscussionsInput;
}>;

export type AllDiscussionsQuery = {
  __typename?: "Query";
  allDiscussions?: Array<{
    __typename?: "Discussion";
    _id?: string | null;
    createdAt?: string | null;
    tattooId?: string | null;
    clientId?: string | null;
    message?: Array<{
      __typename?: "Message";
      id?: string | null;
      senderId?: string | null;
      receiverId?: string | null;
      content?: string | null;
      createdAt?: string | null;
    } | null> | null;
  } | null> | null;
};

export type ArtistOpinionFieldsFragment = {
  __typename?: "Opinion";
  _id?: string | null;
  clientEmail?: string | null;
  picture?: string | null;
  comment?: string | null;
  tattooEmail?: string | null;
  createdAt?: string | null;
  rating?: {
    __typename?: "Rating";
    hygiene?: number | null;
    price?: number | null;
    sympathy?: number | null;
    sum?: number | null;
  } | null;
};

export type ArtistOpinionQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ArtistOpinionQuery = {
  __typename?: "Query";
  artistOpinion?: Array<{
    __typename?: "Opinion";
    _id?: string | null;
    clientEmail?: string | null;
    picture?: string | null;
    comment?: string | null;
    tattooEmail?: string | null;
    createdAt?: string | null;
    rating?: {
      __typename?: "Rating";
      hygiene?: number | null;
      price?: number | null;
      sympathy?: number | null;
      sum?: number | null;
    } | null;
  } | null> | null;
};

export type FindOpinionQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type FindOpinionQuery = {
  __typename?: "Query";
  findOpinion?: {
    __typename?: "Opinion";
    _id?: string | null;
    clientEmail?: string | null;
    picture?: string | null;
    comment?: string | null;
    tattooEmail?: string | null;
    createdAt?: string | null;
    rating?: {
      __typename?: "Rating";
      hygiene?: number | null;
      price?: number | null;
      sympathy?: number | null;
      sum?: number | null;
    } | null;
  } | null;
};

export type CreateOpinionMutationVariables = Exact<{
  createOpinionInput: CreateOpinionInput;
}>;

export type CreateOpinionMutation = {
  __typename?: "Mutation";
  createOpinion: {
    __typename?: "Opinion";
    _id?: string | null;
    clientEmail?: string | null;
    picture?: string | null;
    comment?: string | null;
    tattooEmail?: string | null;
    createdAt?: string | null;
    rating?: {
      __typename?: "Rating";
      hygiene?: number | null;
      price?: number | null;
      sympathy?: number | null;
      sum?: number | null;
    } | null;
  };
};

export type ArtistStatsQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ArtistStatsQuery = {
  __typename?: "Query";
  artistStats?: {
    __typename?: "OpinionStats";
    opinionLength?: number | null;
    scoreTotal?: number | null;
  } | null;
};

export type RdvFieldsFragment = {
  __typename?: "Rdv";
  _id?: string | null;
  size?: string | null;
  color?: string | null;
  placement?: string | null;
  picture1?: string | null;
  picture2?: string | null;
  picture3?: string | null;
  description?: string | null;
  createdAt?: string | null;
  tattooEmail?: string | null;
  clientEmail?: string | null;
  dateRdv?: string | null;
  depositPayed?: boolean | null;
  tattooPayed?: boolean | null;
  discussionId?: string | null;
  canceled?: boolean | null;
  status?: RdvStatus | null;
};

export type CreateRdvMutationVariables = Exact<{
  createRdvInput: CreateRdvInput;
}>;

export type CreateRdvMutation = {
  __typename?: "Mutation";
  createRdv: {
    __typename?: "Rdv";
    _id?: string | null;
    size?: string | null;
    color?: string | null;
    placement?: string | null;
    picture1?: string | null;
    picture2?: string | null;
    picture3?: string | null;
    description?: string | null;
    createdAt?: string | null;
    tattooEmail?: string | null;
    clientEmail?: string | null;
    dateRdv?: string | null;
    depositPayed?: boolean | null;
    tattooPayed?: boolean | null;
    discussionId?: string | null;
    canceled?: boolean | null;
    status?: RdvStatus | null;
  };
};

export type CancelRdvMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CancelRdvMutation = {
  __typename?: "Mutation";
  cancelRdv?: {
    __typename?: "Rdv";
    _id?: string | null;
    size?: string | null;
    color?: string | null;
    placement?: string | null;
    picture1?: string | null;
    picture2?: string | null;
    picture3?: string | null;
    description?: string | null;
    createdAt?: string | null;
    tattooEmail?: string | null;
    clientEmail?: string | null;
    dateRdv?: string | null;
    depositPayed?: boolean | null;
    tattooPayed?: boolean | null;
    discussionId?: string | null;
    canceled?: boolean | null;
    status?: RdvStatus | null;
  } | null;
};

export type ArtistRdvQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ArtistRdvQuery = {
  __typename?: "Query";
  artistRdv?: Array<{
    __typename?: "Rdv";
    _id?: string | null;
    size?: string | null;
    color?: string | null;
    placement?: string | null;
    picture1?: string | null;
    picture2?: string | null;
    picture3?: string | null;
    description?: string | null;
    createdAt?: string | null;
    tattooEmail?: string | null;
    clientEmail?: string | null;
    dateRdv?: string | null;
    depositPayed?: boolean | null;
    tattooPayed?: boolean | null;
    discussionId?: string | null;
    canceled?: boolean | null;
    status?: RdvStatus | null;
  } | null> | null;
};

export type ClientRdvQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ClientRdvQuery = {
  __typename?: "Query";
  clientRdv?: Array<{
    __typename?: "Rdv";
    _id?: string | null;
    size?: string | null;
    color?: string | null;
    placement?: string | null;
    picture1?: string | null;
    picture2?: string | null;
    picture3?: string | null;
    description?: string | null;
    createdAt?: string | null;
    tattooEmail?: string | null;
    clientEmail?: string | null;
    dateRdv?: string | null;
    depositPayed?: boolean | null;
    tattooPayed?: boolean | null;
    discussionId?: string | null;
    canceled?: boolean | null;
    status?: RdvStatus | null;
  } | null> | null;
};

export type TattooSalonFragmentFragment = {
  __typename?: "TattooSalon";
  _id?: string | null;
  name?: string | null;
  description?: string | null;
  picture?: Array<string | null> | null;
  type?: string | null;
  lng?: number | null;
  lat?: number | null;
  email?: string | null;
  isCertified?: boolean | null;
  score?: number | null;
  city?: string | null;
  minPrice?: number | null;
  hourPrice?: number | null;
  instagram?: string | null;
};

export type TattooSalonsQueryVariables = Exact<{
  findAllTattooSalonsInput: FindAllTattooSalonsInput;
}>;

export type TattooSalonsQuery = {
  __typename?: "Query";
  tattooSalons?: Array<{
    __typename?: "TattooSalon";
    _id?: string | null;
    name?: string | null;
    description?: string | null;
    picture?: Array<string | null> | null;
    type?: string | null;
    lng?: number | null;
    lat?: number | null;
    email?: string | null;
    isCertified?: boolean | null;
    score?: number | null;
    city?: string | null;
    minPrice?: number | null;
    hourPrice?: number | null;
    instagram?: string | null;
  } | null> | null;
};

export type TattooSalonQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type TattooSalonQuery = {
  __typename?: "Query";
  tattooSalon?: {
    __typename?: "TattooSalon";
    _id?: string | null;
    name?: string | null;
    description?: string | null;
    picture?: Array<string | null> | null;
    type?: string | null;
    lng?: number | null;
    lat?: number | null;
    email?: string | null;
    isCertified?: boolean | null;
    score?: number | null;
    city?: string | null;
    minPrice?: number | null;
    hourPrice?: number | null;
    instagram?: string | null;
  } | null;
};

export type TattooSalonByEmailQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type TattooSalonByEmailQuery = {
  __typename?: "Query";
  tattooSalonByEmail?: {
    __typename?: "TattooSalon";
    _id?: string | null;
    name?: string | null;
    description?: string | null;
    picture?: Array<string | null> | null;
    type?: string | null;
    lng?: number | null;
    lat?: number | null;
    email?: string | null;
    isCertified?: boolean | null;
    score?: number | null;
    city?: string | null;
    minPrice?: number | null;
    hourPrice?: number | null;
    instagram?: string | null;
  } | null;
};

export type UserFieldsFragment = {
  __typename?: "User";
  _id?: string | null;
  name?: string | null;
  email?: string | null;
  picture?: string | null;
  subscriptionDate?: string | null;
  tattooSalonsFavorite?: Array<string | null> | null;
  notificationByEmailIsActive?: boolean | null;
  notificationBySMSIsActive?: boolean | null;
  phone?: string | null;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: "Query";
  users?: Array<{
    __typename?: "User";
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    picture?: string | null;
    subscriptionDate?: string | null;
    tattooSalonsFavorite?: Array<string | null> | null;
    notificationByEmailIsActive?: boolean | null;
    notificationBySMSIsActive?: boolean | null;
    phone?: string | null;
  } | null> | null;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetUserByEmailQuery = {
  __typename?: "Query";
  getUserByEmail?: {
    __typename?: "User";
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    picture?: string | null;
    subscriptionDate?: string | null;
    tattooSalonsFavorite?: Array<string | null> | null;
    notificationByEmailIsActive?: boolean | null;
    notificationBySMSIsActive?: boolean | null;
    phone?: string | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: {
    __typename?: "User";
    _id?: string | null;
    name?: string | null;
    email?: string | null;
    picture?: string | null;
    subscriptionDate?: string | null;
    tattooSalonsFavorite?: Array<string | null> | null;
    notificationByEmailIsActive?: boolean | null;
    notificationBySMSIsActive?: boolean | null;
    phone?: string | null;
  };
};

export type GetUserTattooSalonsFavoriteQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetUserTattooSalonsFavoriteQuery = {
  __typename?: "Query";
  getUserTattooSalonsFavorite?: Array<{
    __typename?: "TattooSalon";
    _id?: string | null;
    name?: string | null;
    description?: string | null;
    picture?: Array<string | null> | null;
    type?: string | null;
    lng?: number | null;
    lat?: number | null;
    email?: string | null;
    isCertified?: boolean | null;
    score?: number | null;
    city?: string | null;
    minPrice?: number | null;
    hourPrice?: number | null;
    instagram?: string | null;
  } | null> | null;
};

export const DiscussionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DiscussionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Discussion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "senderId" } },
                { kind: "Field", name: { kind: "Name", value: "receiverId" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooId" } },
          { kind: "Field", name: { kind: "Name", value: "clientId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscussionFieldsFragment, unknown>;
export const ArtistOpinionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArtistOpinionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Opinion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "hygiene" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "sympathy" } },
                { kind: "Field", name: { kind: "Name", value: "sum" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistOpinionFieldsFragment, unknown>;
export const RdvFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RdvFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rdv" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "placement" } },
          { kind: "Field", name: { kind: "Name", value: "picture1" } },
          { kind: "Field", name: { kind: "Name", value: "picture2" } },
          { kind: "Field", name: { kind: "Name", value: "picture3" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          { kind: "Field", name: { kind: "Name", value: "dateRdv" } },
          { kind: "Field", name: { kind: "Name", value: "depositPayed" } },
          { kind: "Field", name: { kind: "Name", value: "tattooPayed" } },
          { kind: "Field", name: { kind: "Name", value: "discussionId" } },
          { kind: "Field", name: { kind: "Name", value: "canceled" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RdvFieldsFragment, unknown>;
export const TattooSalonFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TattooSalonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TattooSalon" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "lng" } },
          { kind: "Field", name: { kind: "Name", value: "lat" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "isCertified" } },
          { kind: "Field", name: { kind: "Name", value: "score" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "hourPrice" } },
          { kind: "Field", name: { kind: "Name", value: "instagram" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TattooSalonFragmentFragment, unknown>;
export const UserFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionDate" } },
          { kind: "Field", name: { kind: "Name", value: "tattooSalonsFavorite" } },
          { kind: "Field", name: { kind: "Name", value: "notificationByEmailIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "notificationBySMSIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFieldsFragment, unknown>;
export const FindDiscussionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FindDiscussion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "findDiscussionInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FindDiscussionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findDiscussion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "findDiscussionInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "findDiscussionInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "DiscussionFields" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DiscussionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Discussion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "senderId" } },
                { kind: "Field", name: { kind: "Name", value: "receiverId" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooId" } },
          { kind: "Field", name: { kind: "Name", value: "clientId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindDiscussionQuery, FindDiscussionQueryVariables>;
export const AllDiscussionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllDiscussions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "allDiscussionsInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "AllDiscussionsInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allDiscussions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "allDiscussionsInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "allDiscussionsInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "DiscussionFields" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DiscussionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Discussion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "senderId" } },
                { kind: "Field", name: { kind: "Name", value: "receiverId" } },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooId" } },
          { kind: "Field", name: { kind: "Name", value: "clientId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllDiscussionsQuery, AllDiscussionsQueryVariables>;
export const ArtistOpinionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ArtistOpinion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "artistOpinion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ArtistOpinionFields" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArtistOpinionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Opinion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "hygiene" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "sympathy" } },
                { kind: "Field", name: { kind: "Name", value: "sum" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistOpinionQuery, ArtistOpinionQueryVariables>;
export const FindOpinionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FindOpinion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findOpinion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ArtistOpinionFields" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArtistOpinionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Opinion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "hygiene" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "sympathy" } },
                { kind: "Field", name: { kind: "Name", value: "sum" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindOpinionQuery, FindOpinionQueryVariables>;
export const CreateOpinionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateOpinion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "createOpinionInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateOpinionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createOpinion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "createOpinionInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "createOpinionInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ArtistOpinionFields" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArtistOpinionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Opinion" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rating" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "hygiene" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "sympathy" } },
                { kind: "Field", name: { kind: "Name", value: "sum" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOpinionMutation, CreateOpinionMutationVariables>;
export const ArtistStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ArtistStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "artistStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "opinionLength" } },
                { kind: "Field", name: { kind: "Name", value: "scoreTotal" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistStatsQuery, ArtistStatsQueryVariables>;
export const CreateRdvDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateRdv" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "createRdvInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "CreateRdvInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createRdv" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "createRdvInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "createRdvInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RdvFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RdvFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rdv" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "placement" } },
          { kind: "Field", name: { kind: "Name", value: "picture1" } },
          { kind: "Field", name: { kind: "Name", value: "picture2" } },
          { kind: "Field", name: { kind: "Name", value: "picture3" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          { kind: "Field", name: { kind: "Name", value: "dateRdv" } },
          { kind: "Field", name: { kind: "Name", value: "depositPayed" } },
          { kind: "Field", name: { kind: "Name", value: "tattooPayed" } },
          { kind: "Field", name: { kind: "Name", value: "discussionId" } },
          { kind: "Field", name: { kind: "Name", value: "canceled" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateRdvMutation, CreateRdvMutationVariables>;
export const CancelRdvDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CancelRdv" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelRdv" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RdvFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RdvFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rdv" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "placement" } },
          { kind: "Field", name: { kind: "Name", value: "picture1" } },
          { kind: "Field", name: { kind: "Name", value: "picture2" } },
          { kind: "Field", name: { kind: "Name", value: "picture3" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          { kind: "Field", name: { kind: "Name", value: "dateRdv" } },
          { kind: "Field", name: { kind: "Name", value: "depositPayed" } },
          { kind: "Field", name: { kind: "Name", value: "tattooPayed" } },
          { kind: "Field", name: { kind: "Name", value: "discussionId" } },
          { kind: "Field", name: { kind: "Name", value: "canceled" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelRdvMutation, CancelRdvMutationVariables>;
export const ArtistRdvDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ArtistRdv" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "artistRdv" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RdvFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RdvFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rdv" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "placement" } },
          { kind: "Field", name: { kind: "Name", value: "picture1" } },
          { kind: "Field", name: { kind: "Name", value: "picture2" } },
          { kind: "Field", name: { kind: "Name", value: "picture3" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          { kind: "Field", name: { kind: "Name", value: "dateRdv" } },
          { kind: "Field", name: { kind: "Name", value: "depositPayed" } },
          { kind: "Field", name: { kind: "Name", value: "tattooPayed" } },
          { kind: "Field", name: { kind: "Name", value: "discussionId" } },
          { kind: "Field", name: { kind: "Name", value: "canceled" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArtistRdvQuery, ArtistRdvQueryVariables>;
export const ClientRdvDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientRdv" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientRdv" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RdvFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RdvFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rdv" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "placement" } },
          { kind: "Field", name: { kind: "Name", value: "picture1" } },
          { kind: "Field", name: { kind: "Name", value: "picture2" } },
          { kind: "Field", name: { kind: "Name", value: "picture3" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "tattooEmail" } },
          { kind: "Field", name: { kind: "Name", value: "clientEmail" } },
          { kind: "Field", name: { kind: "Name", value: "dateRdv" } },
          { kind: "Field", name: { kind: "Name", value: "depositPayed" } },
          { kind: "Field", name: { kind: "Name", value: "tattooPayed" } },
          { kind: "Field", name: { kind: "Name", value: "discussionId" } },
          { kind: "Field", name: { kind: "Name", value: "canceled" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientRdvQuery, ClientRdvQueryVariables>;
export const TattooSalonsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TattooSalons" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "findAllTattooSalonsInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FindAllTattooSalonsInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tattooSalons" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "findAllTattooSalonsInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "findAllTattooSalonsInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "TattooSalonFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TattooSalonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TattooSalon" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "lng" } },
          { kind: "Field", name: { kind: "Name", value: "lat" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "isCertified" } },
          { kind: "Field", name: { kind: "Name", value: "score" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "hourPrice" } },
          { kind: "Field", name: { kind: "Name", value: "instagram" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TattooSalonsQuery, TattooSalonsQueryVariables>;
export const TattooSalonDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TattooSalon" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tattooSalon" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "_id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "TattooSalonFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TattooSalonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TattooSalon" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "lng" } },
          { kind: "Field", name: { kind: "Name", value: "lat" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "isCertified" } },
          { kind: "Field", name: { kind: "Name", value: "score" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "hourPrice" } },
          { kind: "Field", name: { kind: "Name", value: "instagram" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TattooSalonQuery, TattooSalonQueryVariables>;
export const TattooSalonByEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TattooSalonByEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tattooSalonByEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "TattooSalonFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TattooSalonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TattooSalon" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "lng" } },
          { kind: "Field", name: { kind: "Name", value: "lat" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "isCertified" } },
          { kind: "Field", name: { kind: "Name", value: "score" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "hourPrice" } },
          { kind: "Field", name: { kind: "Name", value: "instagram" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TattooSalonByEmailQuery, TattooSalonByEmailQueryVariables>;
export const UsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Users" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionDate" } },
          { kind: "Field", name: { kind: "Name", value: "tattooSalonsFavorite" } },
          { kind: "Field", name: { kind: "Name", value: "notificationByEmailIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "notificationBySMSIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const GetUserByEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserByEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserByEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionDate" } },
          { kind: "Field", name: { kind: "Name", value: "tattooSalonsFavorite" } },
          { kind: "Field", name: { kind: "Name", value: "notificationByEmailIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "notificationBySMSIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const UpdateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "updateUserInput" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateUserInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateUserInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "updateUserInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFields" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "subscriptionDate" } },
          { kind: "Field", name: { kind: "Name", value: "tattooSalonsFavorite" } },
          { kind: "Field", name: { kind: "Name", value: "notificationByEmailIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "notificationBySMSIsActive" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserTattooSalonsFavoriteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserTattooSalonsFavorite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserTattooSalonsFavorite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "TattooSalonFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "TattooSalonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TattooSalon" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "_id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "picture" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "lng" } },
          { kind: "Field", name: { kind: "Name", value: "lat" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "isCertified" } },
          { kind: "Field", name: { kind: "Name", value: "score" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "minPrice" } },
          { kind: "Field", name: { kind: "Name", value: "hourPrice" } },
          { kind: "Field", name: { kind: "Name", value: "instagram" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserTattooSalonsFavoriteQuery,
  GetUserTattooSalonsFavoriteQueryVariables
>;
