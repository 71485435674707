import { createManualStoreContext } from "@util/createProvider";
import { ReactNode } from "react";
import {
  ArtistOpinionDocument,
  ArtistStatsDocument,
  CreateOpinionDocument,
  CreateOpinionInput,
  FindOpinionDocument,
} from "../../gql/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNotify } from "@util/notify";
import { useTattooSalon } from "./tattooSalon.provider";
import { t } from "@lingui/macro";

/**
 * Hook to manage the opinion related data of a tattooer.
 *
 * This hook provides the following data:
 * - `artistOpinion`: The opinions of the tattooer.
 * - `artistOpinionResponse`: The response of the `artistOpinion` query.
 * - `artistOpinionLoading`: A boolean indicating if the `artistOpinion` query is loading.
 * - `artistStats`: The stats of the tattooer.
 * - `artistStatsResponse`: The response of the `artistStats` query.
 * - `artistStatsLoading`: A boolean indicating if the `artistStats` query is loading.
 * - `createOpinionMutation`: A function to create an opinion.
 * The function takes a `CreateOpinionInput` as an argument and a callback function to close the modal.
 * - `createOpinionLoading`: A boolean indicating if the `createOpinion` mutation is loading.
 * - `getOpinion`: A function to get an opinion by its id.
 * The function takes an id as an argument and a callback function to close the modal.
 * - `getOpinionResponse`: The response of the `getOpinion` query.
 * - `getOpinionLoading`: A boolean indicating if the `getOpinion` query is loading.
 *
 * @returns An object with the above properties.
 */
function useOpinionStore() {
  const [
    artistOpinion,
    { data: artistOpinionResponse, loading: artistOpinionLoading, refetch: artistOpinionRefetch },
  ] = useLazyQuery(ArtistOpinionDocument);

  const [
    artistStats,
    { data: artistStatsResponse, loading: artistStatsLoading, refetch: artistStatsRefetch },
  ] = useLazyQuery(ArtistStatsDocument);

  const [getOpinion, { data: getOpinionResponse, loading: getOpinionLoading }] =
    useLazyQuery(FindOpinionDocument);

  const [createOpinion, { loading: createOpinionLoading }] = useMutation(CreateOpinionDocument);

  const notify = useNotify();
  const { tattooSalonsRefetch } = useTattooSalon();

  /**
   * Creates an opinion for a tattooer.
   *
   * This function takes a `CreateOpinionInput` as an argument and a callback function to close the modal.
   * It will trigger a mutation to create an opinion and then refetch the tattooer's opinions and stats.
   * It will also refetch the tattooSalons to update the tattooer's score.
   * Finally, it will call the given callback function to close the modal.
   *
   * @param {CreateOpinionInput} data - The input data to create an opinion.
   * @param {() => void} close - The callback function to close the modal.
   */
  function createOpinionMutation(data: CreateOpinionInput, close: () => void) {
    createOpinion({
      variables: {
        createOpinionInput: data,
      },
      onCompleted: async (data) => {
        await artistOpinionRefetch({
          email: data.createOpinion.tattooEmail ?? "",
        });
        await artistStatsRefetch({
          email: data.createOpinion.tattooEmail ?? "",
        });
        await tattooSalonsRefetch();
        notify.success(t`L'avis a bien été envoyée`);
        close();
      },
    });
  }

  return {
    artistOpinion,
    artistOpinionResponse,
    artistOpinionLoading,
    artistStats,
    artistStatsResponse,
    artistStatsLoading,
    createOpinionMutation,
    createOpinionLoading,
    getOpinion,
    getOpinionResponse,
    getOpinionLoading,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useOpinionStore);

type OpinionProviderProps = {
  children: ReactNode;
};
/**
 * The provider for the opinion state.
 *
 * This provider will wrap the component tree of your application and provide
 * the opinion state to all the components that need it.
 *
 * @example
 * import { OpinionProvider } from "src/provider/opinion.provider";
 *
 * const App = () => {
 *   return (
 *     <OpinionProvider>
 *       <YourApp />
 *     </OpinionProvider>
 *   );
 * };
 *
 * @param {OpinionProviderProps} props - The props of the component.
 * @returns {JSX.Element} - The component.
 */
export function OpinionProvider({ children }: OpinionProviderProps) {
  return <StoreProvider store={useOpinionStore()}>{children}</StoreProvider>;
}

export const useOpinion = useStore;
