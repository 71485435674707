import { MAX_SIZE_MIO } from "@components/layout/Messaging";
import { t } from "@lingui/macro";
import { useDiscussion } from "@provider/discussion.provider";
import { useNotify } from "@util/notify";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  DisplayImage,
  ImageStyleProps,
} from "@components/fundamental/DisplayImage/DisplayImage.fundamental";
import { PlusSquareFill, XCircleFill } from "react-bootstrap-icons";
import styled from "styled-components";
import { FlexColumnLayout, FlexRowLayout } from "reonelabs-ui";
import { WheelLoader } from "@pages/Home/Map/TattooSalonList/TattooSalonCard/TattooSalonDetail/TattooSalonCreateOpinion/PictureStep";
import { cloudinaryDestroy } from "@api/cloudinary";

type UploadImageProps = {
  onUpload: (image: string) => void;
  style?: ImageStyleProps;
  defaultImage: string | null;
  uploadHeight?: string;
};

/**
 * A component that displays an upload button and a file input field.
 * When the button is clicked, the file input field is opened for file selection.
 * When a file is selected, it is sent to the server and the component is updated with the response.
 * If the image is too large, an error message is displayed.
 * The component is also updated with the default image if provided.
 * It is now possible to delete the uploaded image.
 *
 * @param {UploadImageProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export function UploadImage({ onUpload, style, defaultImage, uploadHeight }: UploadImageProps) {
  const fileInputRef = useRef(null);
  const notify = useNotify();
  const { uploadImageToCDN } = useDiscussion();

  const [image, setImage] = useState<string | null>(defaultImage);
  const [isUploading, setIsUploading] = useState(false);

  const handleButtonClick = () => {
    if (!fileInputRef.current) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore click function always exists
    fileInputRef.current.click();
  };

  async function sendImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      notify.error(t`Aucune image sélectionnée`);
      return;
    }

    const file = event?.target?.files[0];
    /**
     * Checks if the image file is too large. If so, displays an error message and returns.
     */
    if (file.size >= MAX_SIZE_MIO) {
      notify.error(t`Image trop volumineuse`);
      return;
    }
    const reader = new FileReader();

    setIsUploading(true);

    /**
     * Handles the FileReader's onloadend event, converts the image file to base64 string and emits an "image" event with tattooId, clientId, message, image and createdAt.
     *
     * @param {Event} event - The onloadend event triggered by the FileReader.
     * @return {void} This function returns nothing.
     */
    reader.onloadend = async () => {
      const base64Image = reader.result as string;

      uploadImageToCDN.mutate(base64Image, {
        onSuccess: (response) => {
          setImage(response.data.public_id);
          onUpload(response.data.public_id);
          setIsUploading(false);
        },
        onError: () => {
          setIsUploading(false);
          notify.error(t`Erreur lors du téléchargement de l'image`);
        },
      });
    };

    reader.readAsDataURL(file);
  }

  async function deleteImage() {
    if (!image) return;
    try {
      await cloudinaryDestroy(image as string);
      setImage(null);
      onUpload("");
      notify.success(t`Image supprimée`);
    } catch (error) {
      notify.error(t`Erreur lors de la suppression de l'image`);
    }
  }

  useEffect(() => {
    if (defaultImage) {
      setImage(defaultImage);
    }
  }, [defaultImage]);

  return (
    <>
      {!image ? (
        <PictureStepContainer
          $justifyContent="center"
          $alignItems="center"
          $gap={1.25}
          onClick={handleButtonClick}
          style={{ height: uploadHeight }}
        >
          {isUploading || uploadImageToCDN.isPending ? (
            <WheelLoader />
          ) : (
            <PlusSquareFill size={24} color="white" />
          )}
        </PictureStepContainer>
      ) : (
        <ImageContainer>
          <DisplayImage imageID={image} style={{ maxWidth: "100%", maxHeight: "100%", ...style }} />
          <DeleteButton onClick={deleteImage}>
            <XCircleFill size={24} color="white" />
          </DeleteButton>
        </ImageContainer>
      )}

      <input type="file" onChange={sendImage} ref={fileInputRef} style={{ display: "none" }} />
    </>
  );
}

const PictureStepContainer = styled(FlexColumnLayout)`
  box-sizing: border-box;
  border-radius: var(--Border-Radius-rounded, 4px);
  height: 100%;
  background: var(--Layout-Nav-Text, #a3a3a3);
  padding: 0px var(--spacings-520-px-125-rem, 20px);
`;

const ImageContainer = styled(FlexRowLayout)`
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;
