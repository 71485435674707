import axios from "axios";
import { apiUrl } from "../config";

/**
 * Sends a POST request to the specified route with the provided data.
 *
 * @param {string} routes - The route to which the POST request will be sent.
 * @param {TRequest} data - The data to be sent in the POST request.
 * @return {Promise<TResponse>} A Promise that resolves to the response data from the POST request.
 */
export function post<TRequest, TResponse>(routes: string, data: TRequest): Promise<TResponse> {
  return axios.post<TRequest, TResponse>(`${apiUrl}/api/${routes}`, data);
}
