import { DateTime } from "luxon";

/**
 * Formats the input date string into a specific date and time format.
 *
 * @param {string} date - The date string to be formatted.
 * @return {string} The formatted date and time string.
 */
export function formatDate(date: string) {
  if (!date) return "";
  const dt = DateTime.fromISO(date);

  return dt.toFormat("dd/MM/yyyy HH:mm:ss");
}

/**
 * Determines if the given date is in the past.
 *
 * @param {string} date - The date string to check.
 * @return {boolean} True if the date is in the past, false otherwise.
 */
export function isPastDate(date: string) {
  const dt = DateTime.fromISO(date);
  return dt < DateTime.now();
}

/**
 * Formats a date string into a human-readable format, taking into account the current date.
 * The format will be "Aujourd'hui HH:mm" if the date is today, "Hier HH:mm" if the date is yesterday,
 * and "dd/MM/yyyy HH:mm" otherwise.
 *
 * @param {string} dateString - The date string to be formatted in ISO format.
 * @return {string} The formatted date string.
 */
export function formatDateToDisplay(
  dateString: string | undefined | null,
  withoutTime = false,
): string {
  if (!dateString) return "-";
  const date = DateTime.fromFormat(dateString, "dd/MM/yyyy HH:mm:ss");
  const now = DateTime.now();

  const diffInDays = now.diff(date, "days").days;
  let formattedDate: string;

  if (diffInDays < 1 && date.hasSame(now, "day")) {
    formattedDate = `Aujourd'hui ${date.toFormat(withoutTime ? "" : "HH:mm")}`;
  } else if (diffInDays < 2 && date.hasSame(now.minus({ days: 1 }), "day")) {
    formattedDate = `Hier ${date.toFormat(withoutTime ? "" : "HH:mm")}`;
  } else {
    formattedDate = `le ${date.toFormat(withoutTime ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm")}`;
  }

  return formattedDate;
}
