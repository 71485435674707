import { cloudinaryDestroy } from "@api/cloudinary";
import { DeleteButton } from "@components/element/UploadImage";
import { DisplayImage } from "@components/fundamental/DisplayImage/DisplayImage.fundamental";
import { MAX_SIZE_MIO } from "@components/layout/Messaging";
import { t } from "@lingui/macro";
import { useDiscussion } from "@provider/discussion.provider";
import { useStepper } from "@provider/stepper.provider";
import { useNotify } from "@util/notify";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { PlusSquareFill, XCircleFill } from "react-bootstrap-icons";
import { FlexColumnLayout, FlexRowLayout } from "reonelabs-ui";
import styled, { keyframes } from "styled-components";

/**
 * This component is a stepper step to upload a picture.
 * It's the second step of the stepper.
 * It displays a button to upload a picture and a file input field.
 * When the button is clicked, the file input field is opened for file selection.
 * When a file is selected, it's sent to the server and the step is updated with the response.
 * If the image is too large, an error message is displayed.
 * @returns A JSX.Element with the step content.
 */
export function PictureStep() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const notify = useNotify();
  const { uploadImageToCDN } = useDiscussion();
  const { updateStepperValues, getValue } = useStepper();
  const [isUploading, setIsUploading] = useState(false);

  const image = useMemo(() => {
    const value = getValue("picture");
    if (value === "picture") return null;
    return String(value);
  }, [getValue]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  async function sendImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || event.target.files.length === 0) {
      notify.error(t`Aucune image sélectionnée`);
      return;
    }

    const file = event.target.files[0];
    if (file.size >= MAX_SIZE_MIO) {
      notify.error(t`Image trop volumineuse`);
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Image = reader.result as string;

      uploadImageToCDN.mutate(base64Image, {
        onSuccess: (response) => {
          updateStepperValues({
            picture: response.data.public_id,
          });
          setIsUploading(false);
        },
        onError: () => {
          setIsUploading(false);
          notify.error(t`Erreur lors du téléchargement de l'image`);
        },
      });
    };

    reader.readAsDataURL(file);
  }

  const handleDeleteImage = async () => {
    if (image) {
      await cloudinaryDestroy(image);
      updateStepperValues({
        picture: undefined,
      });
      notify.success(t`Image supprimée`);

      // Réinitialiser la valeur du champ de fichier
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <>
      {!image ? (
        <PictureStepContainer
          $justifyContent="center"
          $alignItems="center"
          $gap={1.25}
          onClick={handleButtonClick}
        >
          {isUploading || uploadImageToCDN.isPending ? (
            <WheelLoader />
          ) : (
            <PlusSquareFill size={24} color="white" />
          )}
        </PictureStepContainer>
      ) : (
        <ImageContainer>
          <DisplayImage imageID={image} style={{ maxWidth: "100%", maxHeight: "100%" }} />
          <DeleteButton onClick={handleDeleteImage}>
            <XCircleFill size={24} color="white" />
          </DeleteButton>
        </ImageContainer>
      )}

      <input
        type="file"
        onChange={sendImage}
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
      />
    </>
  );
}

const PictureStepContainer = styled(FlexColumnLayout)`
  box-sizing: border-box;
  border-radius: var(--Border-Radius-rounded, 4px);
  height: 100%;
  background: var(--Layout-Nav-Text, #a3a3a3);
  padding: 0px var(--spacings-520-px-125-rem, 20px);
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const WheelLoader = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-top: 3px solid #a3a3a3;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
`;

const ImageContainer = styled(FlexRowLayout)`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 67dvh;
    object-fit: cover;
    border-radius: 4px;
  }
`;
