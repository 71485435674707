import { ReactNode } from "react";
import { FlexColumnLayout } from "reonelabs-ui";
import styled from "styled-components";

type CardProps = {
  children: ReactNode;
  gap?: number;
  isCentered?: boolean;
};

/**
 * A component that renders its children in a card.
 *
 * @example
 * <Card>
 *   <h1>Hello, world!</h1>
 * </Card>
 *
 * @param {CardProps} props - The props object containing the following properties:
 *   - children: The children components to be rendered in the card.
 */
export function Card({ children, gap, isCentered }: CardProps) {
  return (
    <CardContainer $alignItems={isCentered ? "center" : "flex-start"} $gap={gap ?? 1}>
      {children}
    </CardContainer>
  );
}
const CardContainer = styled(FlexColumnLayout)`
  box-sizing: border-box;
  border-radius: var(--Border-Radius-rounded, 4px);
  border: 1px solid var(--Layout-Nav-Text, #a3a3a3);
  padding: var(--spacings-3514-px-0875-rem, 14px);
`;
