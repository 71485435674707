/**
 * Deep merge two objects. If the same key exists in both objects,
 * and the value is an object, it will be merged recursively.
 * @param target The target object to be merged into.
 * @param source The source object to be merged from.
 * @returns A new object that is the result of the deep merge.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepMerge(target: any, source: any) {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
}

/**
 * Formats a number to have a single decimal place.
 * @param number - The number to format.
 * @returns The formatted number.
 */
export function formatNumber(number: number) {
  return number.toFixed(1);
}
