import { apiUrl } from "@config/index";
import { useDiscussion } from "@provider/discussion.provider";
import { useUser } from "@provider/user.provider";
import { formatDate, formatDateToDisplay } from "@util/date";
import { DateTime } from "luxon";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import { Message } from "../../../../gql/graphql";
import { MessagingPayload } from "../../../types/messaging.types";
import { FlexColumnLayout, FlexRowLayout, Input, Text } from "reonelabs-ui";
import styled from "styled-components";
import { z } from "zod";
import { useNotify } from "@util/notify";
import { t } from "@lingui/macro";
import { useParams } from "@tanstack/react-router";
import { StyledPropsFormatted } from "reonelabs-ui/src/lib/types";
import { PlusSquareFill, SendFill } from "react-bootstrap-icons";
import { Loader, TattooHeader } from "@components/element";
import { Cloudinary } from "@cloudinary/url-gen";
import { auto } from "@cloudinary/url-gen/actions/resize";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { AdvancedImage } from "@cloudinary/react";
import { lazyload } from "@cloudinary/react";
import { Card } from "@components/fundamental/Card/Card";

const NO_FILTER_MESSAGE: Array<string> = ["[DEMAND_SEND]"];

export const MAX_SIZE_MIO = 5 * 1024 * 1024; // 5 Mio en octets

/**
 * Checks if the given content is an image by checking if it includes the string "dqnacyz8p".
 *
 * @param {string | undefined} content - The content to be checked.
 * @return {boolean | undefined} Returns `true` if the content is an image, `false` otherwise.
 *                               Returns `undefined` if the content is `undefined`.
 */
export function isImageContent(content: string | undefined) {
  if (!content) return;
  return content.includes("___:");
}

const socket = io(apiUrl, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ["websocket"],
});

const chatSchema = z.object({
  message: z.string(),
});

type MessagingData = z.infer<typeof chatSchema>;

/**
 * Renders the Messaging component, which displays a chat interface for sending and receiving messages.
 * It fetches messages from the server using the `useDiscussion` hook and updates the `allMessages` state
 * whenever a new message is received. It also listens for new messages using the `socket.io` library.
 * The component renders a list of messages, each displayed in a `MessageContainer` component with the sender's
 * name and the message content. If the message content is an image, it is displayed using the `MessageImage`
 * component. The user can send messages by typing in the input field and clicking the "Send" button, or by
 * uploading an image file and clicking the "Send" button. The image is converted to base64 format and sent
 * to the server using the `socket.io` library.
 *
 * @return {JSX.Element} The rendered Messaging component.
 */
export function Messaging() {
  const { userConnected } = useUser();
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState<Array<Message>>([]);
  const { tattooDiscussionQuery, tattooDiscussion, uploadImageToCDN } = useDiscussion();
  const notify = useNotify();
  const messagesEndRef = useRef(null);

  const { idDiscussions } = useParams({
    from: "/message/discussions/$idDiscussions",
  });
  const { idTattoo } = useParams({
    from: "/message/new/$idTattoo",
  });

  const tattooId = useMemo(() => {
    // when the normal user speak with a tattoo
    if (idTattoo) return idTattoo;
    return tattooDiscussion?.findDiscussion?.tattooId ?? "not-found";
  }, [tattooDiscussion, idTattoo]);

  const clientId = useMemo(() => {
    // when the normal user speak with a tattoo
    if (idTattoo) return userConnected.email;
    return tattooDiscussion?.findDiscussion?.clientId ?? "not-found";
  }, [tattooDiscussion, idTattoo, userConnected]);

  useEffect(() => {
    if (!tattooDiscussion?.findDiscussion?.message) return;

    const oldMessage: Array<Message> = tattooDiscussion.findDiscussion.message as Array<Message>;

    setAllMessages(oldMessage);
  }, [tattooDiscussion]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore not necessary to fix the type error because react ref have a bad type.
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
    socket.on("message", (message) => {
      setAllMessages((allMessages) => [...allMessages, message]);
    });
    // need to clean up
    return () => {
      socket.off("message");
    };
  }, [allMessages]);

  useEffect(() => {
    tattooDiscussionQuery({
      variables: {
        findDiscussionInput: {
          clientId,
          tattooId,
          idDiscussions: idDiscussions === "new" ? null : idDiscussions,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tattooDiscussionQuery, userConnected, idDiscussions]);

  /**
   * Sends a new message using the provided messaging data.
   *
   * @param {MessagingData} messagingData - The data for the new message.
   * @return {void} This function does not return anything.
   */
  function sendMessage(messagingData: MessagingData) {
    const newMessage: MessagingPayload = {
      tattooId: tattooId,
      clientId,
      message: {
        senderId: userConnected.email,
        receiverId: tattooId,
        content: messagingData.message,
        createdAt: formatDate(DateTime.now().toString()),
      },
    };
    socket.emit("message", newMessage);
  }

  /**
   * Asynchronously sends an image file selected by the user via a change event.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The change event triggered by the user selecting an image file.
   * @return {Promise<void>} A promise that resolves when the image is sent successfully, or rejects with an error if there was a problem.
   */
  async function sendImage(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      notify.error(t`Aucune image sélectionnée`);
      return;
    }

    const file = event?.target?.files[0];
    /**
     * Checks if the image file is too large. If it is, it displays an error message and returns.
     */
    if (file.size >= MAX_SIZE_MIO) {
      notify.error(t`Image trop volumineuse`);
      return;
    }
    const reader = new FileReader();

    /**
     * Handles the onloadend event of the FileReader, converting the image file to a base64 string and emitting an "image" event with the tattooId, clientId, message, image, and createdAt.
     *
     * @param {Event} event - The onloadend event triggered by the FileReader.
     * @return {void} This function does not return anything.
     */
    reader.onloadend = async () => {
      const base64Image = reader.result as string;

      uploadImageToCDN.mutate(base64Image, {
        onSuccess: (response) => {
          socket.emit("image", {
            tattooId: tattooId,
            clientId,
            message: {
              senderId: userConnected.email,
              receiverId: tattooId,
              content: "not_an_image",
              createdAt: formatDate(DateTime.now().toString()),
            },
            image: response.data,
            createdAt: formatDate(DateTime.now().toString()),
          });
        },
      });
    };

    reader.readAsDataURL(file);
  }

  /**
   * Updates the message state based on the provided value.
   *
   * @param {string | number} value - The new value for the message. If it is a number, the function returns early.
   * @return {void} This function does not return anything.
   */
  function changeMessage(value: string | number) {
    if (typeof value === "number") {
      return;
    }
    setMessage(value);
  }

  /**
   * Renders a send button component that triggers a message to be sent when clicked.
   *
   * @return {JSX.Element} The rendered send button component.
   */
  function SendButton() {
    function handleSendMessage() {
      if (!message) return;
      sendMessage({ message: message });
      setMessage("");
    }

    return <SendFill size={24} onClick={handleSendMessage} style={{ cursor: "pointer" }} />;
  }

  /**
   * Renders a component that displays an image upload button and a file input field.
   * When the button is clicked, it triggers the file input field to open for file selection.
   *
   * @return {JSX.Element} The rendered component.
   */
  function SendImage() {
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
      if (!fileInputRef.current) return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore click function always exists
      fileInputRef.current.click();
    };
    return (
      <>
        <PlusSquareFill size={24} onClick={handleButtonClick} style={{ cursor: "pointer" }} />
        <input type="file" onChange={sendImage} ref={fileInputRef} style={{ display: "none" }} />
      </>
    );
  }
  const cld = new Cloudinary({ cloud: { cloudName: "dqnacyz8p" } });

  const rdvStatusIsDemandSend = useMemo(() => {
    const lastIndex: number = allMessages.length - 1;

    if (!allMessages[lastIndex]) {
      return false;
    }

    return (
      allMessages[lastIndex].content === "[DEMAND_SEND]" &&
      allMessages[lastIndex].senderId === tattooId
    );
  }, [allMessages, tattooId]);

  function getImageId(content: string) {
    return content.replaceAll("___:", "").replace(":___", "");
  }

  function handleEnter() {
    if (!message) return;
    sendMessage({ message: message });
    setMessage("");
  }

  return (
    <MessagingContainer>
      {uploadImageToCDN.isPending && <Loader />}
      <TattooHeader
        tattooEmail={tattooId}
        clientEmail={clientId}
        backRedirectUrl="/rdv"
        isTattooAccount={userConnected?.email === tattooId}
      />
      <AllMessageLayout $alignItems="space-between" $justifyContent="flex-start">
        {allMessages
          .filter((msg) => !NO_FILTER_MESSAGE.includes(msg.content ?? ""))
          .map((msg: Message, index) => (
            <FlexColumnLayout
              key={index}
              $justifyContent="flex-start"
              $alignItems={msg.senderId === userConnected.email ? "flex-end" : "flex-start"}
              $gap={0.4}
            >
              <MessageContainer $isSender={msg.senderId === userConnected.email}>
                {isImageContent(msg.content ?? "") ? (
                  <AdvancedImage
                    loading="lazy"
                    cldImg={cld
                      .image(getImageId(msg.content ?? ""))
                      .format("auto")
                      .quality("auto")
                      .resize(auto().gravity(autoGravity()))
                      .addFlag("progressive:semi")}
                    plugins={[lazyload()]}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <Text $size="S" $color={msg.senderId === userConnected.email ? "#FFF" : "#000"}>
                    {msg.content}
                  </Text>
                )}
              </MessageContainer>
              <Text $size="XXS" $color="#A3A3A3">
                {formatDateToDisplay(msg.createdAt ?? "")}
              </Text>
            </FlexColumnLayout>
          ))}
        <div ref={messagesEndRef} />
      </AllMessageLayout>
      <FormMessageLayout $justifyContent="space-between" $alignItems="center">
        {rdvStatusIsDemandSend ? (
          <CustomMessageContainer>
            <Card>
              <Text $size="XS">
                Le tatoueur est en train de traiter votre message. Veuillez patienter ...
              </Text>
            </Card>
          </CustomMessageContainer>
        ) : (
          <InputStyle>
            <Input
              type="text"
              label=""
              size="large"
              variant="primary"
              placeholder={t`Add something...`}
              defaultValue={message}
              name="message"
              LeftIcon={SendImage}
              RightIcon={SendButton}
              onChange={changeMessage}
              onKeyDown={handleEnter}
            />
          </InputStyle>
        )}
      </FormMessageLayout>
    </MessagingContainer>
  );
}

const MessagingContainer = styled(FlexColumnLayout)``;

export const InputStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  fieldset {
    border-radius: var(--Input-Border-radius, 8px);
    border: var(--Spacings-px---1px, 1px) solid var(--Input-Primary-Default-Border, #1c1917);
    background: var(--Input-Primary-Default-Background, #fafaf9);

    svg {
      color: #0c0a09;
      width: var(--sizes-button-input-nav-large-line-height, 24px);
      height: var(--sizes-button-input-nav-large-line-height, 24px);
    }
    input {
      &::placeholder {
        color: #44403c !important;
      }
      &::-webkit-input-placeholder {
        color: #44403c !important;
      }
      &::-moz-placeholder {
        color: #44403c !important;
      }
      &:-ms-input-placeholder {
        color: #44403c !important;
      }
    }

    &:focus-within {
      border-radius: var(--Input-Border-radius, 8px);
      border: var(--Spacings-px---1px, 1px) solid var(--Input-Primary-Default-Border, #1c1917);
    }
  }
`;

export const CustomMessageContainer = styled(FlexRowLayout)`
  padding: 1.25rem;
  & div {
    width: 80%;
    margin: auto;
  }
`;

const AllMessageLayout = styled(FlexColumnLayout)`
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  height: 65dvh;
`;

const FormMessageLayout = styled(FlexColumnLayout)`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10vh;
  fieldset {
    margin: 2dvh;
  }
`;

type MessageContainerProps = StyledPropsFormatted<{
  isSender: boolean;
}>;

const MessageContainer = styled.div<MessageContainerProps>`
  background-color: ${({ $isSender }) => (!$isSender ? "#FFF" : "var(--colors-indigo-500)")};
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ $isSender }) =>
    !$isSender
      ? ` border-radius: var(--Border-Radius-rounded-none, 0px) var(--Border-Radius-rounded-3xl, 24px)
    var(--Border-Radius-rounded-3xl, 24px) var(--Border-Radius-rounded-3xl, 24px);`
      : `border-radius: var(--Border-Radius-rounded-3xl, 24px) var(--Border-Radius-rounded-none, 0px)
    var(--Border-Radius-rounded-3xl, 24px) var(--Border-Radius-rounded-3xl, 24px);`}
  border: 1px solid #09090b;
  padding: 15px;
  gap: 0px;
  max-width: 175px;
  p {
    word-wrap: break-word;
  }
`;
