import { t } from "@lingui/macro";
import { ObjValues, SafeMap } from "../types";
import { createManualStoreContext } from "@util/createProvider";
import { deepMerge } from "@util/global";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { getValueInNestedObject } from "reonelabs-ui";

type StepperStep = {
  title: string;
  element: ReactNode;
  requiredField?: Array<string>;
};

/**
 * Creates a stepper store with the given steps.
 *
 * @param {Array<StepperStep>} steps - the steps of the stepper
 * @return {Object} an object containing the state of the stepper
 */
function useStepperStore(steps: Array<StepperStep>) {
  const [currentStep, setCurrentStep] = useState(1);
  const [values, setValues] = useState<SafeMap<ObjValues>>({});
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    function checkStepIsValid() {
      const step = steps[currentStep - 1];
      let isStepValid = true;

      step.requiredField?.forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore type check is made in getValueInNestedObject
        const value = getValueInNestedObject<SafeMap<ObjValues>>(values, key);
        if (!value || value === key) {
          setError(t`Un des champs requis n'est pas rempli`);
          isStepValid = false;
        }
      });

      setIsValid(isStepValid);
    }

    checkStepIsValid();
  }, [values, currentStep, steps]);

  /**
   * Goes to the next step if the current step is valid.
   * If the current step is not valid, it does not change the current step.
   * If the current step is the last step, it does not change the current step.
   */
  function handleNext() {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setIsValid(false);
    }
  }

  /**
   * Goes to the previous step if the current step is greater than 1.
   * If the current step is 1, it does not change the current step.
   */
  function handlePrevious() {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  }

  /**
   * Updates the values of the stepper.
   * It merges the given values with the current values of the stepper.
   * @param values The new values to merge with the current values.
   */
  function updateStepperValues(values: SafeMap<ObjValues>) {
    setValues((newValues) => deepMerge(newValues, values));
  }

  /**
   * Gets a value from the stepper's values by its key.
   * It uses getValueInNestedObject to get the value, so it can be a nested value.
   * @param key The key of the value to get.
   * @returns The value associated with the given key.
   */
  function getValue(key: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore type check is made in getValueInNestedObject
    return getValueInNestedObject<SafeMap<ObjValues>>(values, key);
  }

  const progressPercentage = useMemo(() => {
    return (currentStep / steps.length) * 100;
  }, [currentStep, steps]);

  return {
    currentStep,
    handleNext,
    handlePrevious,
    progressPercentage,
    steps,
    updateStepperValues,
    values,
    getValue,
    error,
    isValid,
  };
}

const { StoreProvider, useStore } = createManualStoreContext(useStepperStore);

type StepperProviderProps = {
  children: ReactNode;
  steps: Array<StepperStep>;
};
/**
 * A provider component that wraps its children with a StoreProvider component
 * and provides the stepperStore as the store prop.
 *
 * @param {StepperProviderProps} children - The children components to be wrapped by the provider
 * @param {Array<StepperStep>} steps - The steps of the stepper
 * @return {JSX.Element} The provider component with the provided store
 */
export function StepperProvider({ children, steps }: StepperProviderProps) {
  return <StoreProvider store={useStepperStore(steps)}>{children}</StoreProvider>;
}

export const useStepper = useStore;
