import { createRootRoute } from "@tanstack/react-router";
import { Index } from "../page/Index/Index";

export const Route = createRootRoute({
  /**
   * A function that returns a component based on the user's authentication status.
   *
   * @return {JSX.Element} The component to be rendered based on the user's authentication status.
   */
  component: Index,
});
