import { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Renders a loader component that displays a progress bar that increases in width by 10% every 2 seconds.
 *
 * @return {JSX.Element} The loader component.
 */
export function Loader() {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPercentage((prevPercentage) => (prevPercentage >= 100 ? 0 : prevPercentage + 10));
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ProgressBarContainer>
      <ProgressBar $percentage={percentage} />
    </ProgressBarContainer>
  );
}

const ProgressBarContainer = styled.div`
  position: fixed;
  top: 1%;
  left: 0;
  width: 100%;
  background-color: var(--colors-purple-50);
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ $percentage: number }>`
  height: 100%;
  width: ${({ $percentage }) => `${$percentage}%`};
  background-color: ${({ theme }) => theme.light.colors.primaryBrand.medium};
  transition: width 0.05s ease-out;
`;
